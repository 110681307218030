//Get the button:
mybutton = document.querySelector(".goto-top-button");

function setupScrollEvent() {
    const scrollButton = document.querySelector('.scroll-top');

    scrollButton.addEventListener('click', (e) => {
        // not the best solution until Safari/Edge support scroll behavior
        // window.scrollTo({ top: 0, behavior: 'smooth' });

        // Thanks to Geroge Daniel https://stackoverflow.com/questions/51229742/javascript-window-scroll-behavior-smooth-not-working-in-safari
        scrollToSmoothly(0, 1000);
    });
}

// prepares the window for a scroll event to show the scroll button
function setupScrollListener() {
    const scrollBreakpoint = window.innerHeight * 0.9;
    window.addEventListener('scroll', (e) => {
        const scrollButton = document.querySelector('.scroll-top');

        // const scrollOffset = document.scrollingElement.scrollTop;
        const scrollOffset = window.scrollY;

        if (scrollOffset >= scrollBreakpoint) {
            scrollButton.classList.add('visible');
        } else if (scrollOffset <= 0) {
            scrollButton.classList.remove('visible');
        }
    });
}

function scrollToSmoothly(pos, time) {
    var currentPos = window.pageYOffset;
    var start = null;
    if(time == null) time = 500;
    pos = +pos, time = +time;
    window.requestAnimationFrame(function step(currentTime) {
        start = !start ? currentTime : start;
        var progress = currentTime - start;
        if (currentPos < pos) {
            window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
        } else {
            window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
        }
        if (progress < time) {
            window.requestAnimationFrame(step);
        } else {
            window.scrollTo(0, pos);
        }
    });
}

function getPics() {
    const imgs = document.querySelectorAll('.image');
    const fullPage = document.querySelector('#fullpage');

    imgs.forEach(img => {
        img.addEventListener('click', function() {
            fullPage.style.backgroundImage = 'url(' + img.src + ')';
            fullPage.style.display = 'block';
        });
    });
}



document.addEventListener('DOMContentLoaded', () => {
    setupScrollListener();
    setupScrollEvent();
    getPics();
});
